import { FC } from 'react';
import {
  Button,
  Flex,
  FlexProps,
  IconButton,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ListSelectorProps {
  label: string;
  labelProps?: FieldLabelProps;
  extraLabel?: string;
  extraLabelProps?: TextProps;
  value?: string;
  valueContainerProps?: FlexProps;
  valueTextProps?: TextProps;
  placeholder?: string;
  onClick?: () => void;
  onClear?: () => void;
  containerProps?: FlexProps;
}

export const ListSelector: FC<ListSelectorProps> = ({
  label,
  labelProps,
  // extraLabel,
  // extraLabelProps,
  value,
  placeholder,
  valueContainerProps,
  valueTextProps,
  onClick,
  onClear,
  containerProps,
}) => {
  const hasValue = value?.length > 0;

  return (
    <Flex flexDirection="column" onClick={onClick} {...containerProps}>
      <FieldLabel text={label} {...labelProps} />
      <Flex
        width="100%"
        borderStyle="solid"
        borderWidth={1}
        borderRadius="lg"
        borderColor="blackAlpha.200"
        minH={12}
        alignItems="center"
        pl={4}
        pr={1}
        fontSize="lg"
        cursor="pointer"
        color={hasValue ? 'blackAlpha.800' : 'blackAlpha.500'}
        {...valueContainerProps}
      >
        <Text flexGrow={1} {...valueTextProps}>
          {value ? value : placeholder ? placeholder : label}
        </Text>
        <Button size="sm" variant="transparent" onClick={onClick}>
          {value ? 'Change' : 'Choose'}
        </Button>
        {!!onClear && value && (
          <IconButton
            aria-label="Clear"
            icon={<Icon name={ICON_NAMES.x} />}
            size="sm"
            variant="transparent"
            onClick={onClear}
          />
        )}
      </Flex>
    </Flex>
  );
};
